@import '@{variablesLocation}';
@import './Components/DealerDetails';
@import './Components/PartnerModal';

#REACT_dealerLocator {
  .header {
    @media screen and (max-width: 767px) {
      padding: 0 0.5rem;
    }

    h1 {
      .fa-stack {
        font-size: 11px;
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .dealer-locator-wrapper {
    position: relative;

    .map-container {
      height: 80rem;
      width: 100%;

      &.mobile-list {
        position: absolute;
      }

      #map {
        height: 100%;

        .cluster {
          div {
            color: @white-color !important;
          }
        }
      }
    }

    .sidebar {
      width: 300px;
      position: absolute;
      top: 1rem;
      left: 2.5rem;
      z-index: 1;

      @media screen and (max-width: 767px) {
        position: static;
        width: auto;
        top: 0;
        left: 0;
      }

      .panel {
        padding: 1rem;
        margin-bottom: 5px;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 0 9px rgb(0 0 0 / 23%);
        -webkit-box-shadow: 0 0 9px rgb(0 0 0 / 23%);
        overflow: auto;

        @media screen and (max-width: 767px) {
          overflow: visible;
          box-shadow: none;
          -webkit-box-shadow: none;
        }

        &.search {
          overflow-y: hidden;
          select {
            height: 3.7rem;
            margin-bottom: 5px;
            appearance: auto;
          }

          button.btn__geolocateUser{
            min-width: 2rem;
            i, &:hover i{
              background-color: unset !important;
            }
          }
        }

        &.results {
          max-height: 495px;

          @media screen and (max-width: 767px) {
            max-height: none;
          }

          ol {
            margin-bottom: 0;
          }
        }

        &.mobile-switch {
          i {
            color: @Standard;
            text-decoration: none !important;
            margin-right: 0.6em;
          }

          a {
            background: transparent;
            border: none;
            color: @CTAPrimary;
            padding: 0;
          }
        }

        &.online {
          max-height: 150px;

          @media screen and (max-width: 767px) {
            max-height: none;
          }

          .online-dealers-title {
            font-weight: 400;
            font-size: 14px;
            color: @Black;
            line-height: 1;
            margin-bottom: 8px;
          }
        }

        &.results,
        &.online {
          li {
            font-size: 12px;
            line-height: 1.167;
            border-bottom: 1px solid @Border;
            padding-bottom: 7px;
            margin-bottom: 8px;
            cursor: pointer;

            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
              margin-bottom: 0;
            }

            &.rating-special {
              position: relative;
              padding-top: 16px;
            }

            .dealer-link {
              color: #121212;
              display: block;
            }

            .rating-special-label {
              font-weight: 400;
              font-size: 8px;
              text-transform: uppercase;
              position: absolute;
              top: 0;
              background-color: @Black;
              padding: 2px 3px;
              color: #fff;
            }

            .dealer-name {
              font-weight: 700;
              font-size: 12px;
              margin-bottom: 5px;
              line-height: 1;

              .dealer-expand {
                float: right;
              }
            }
          }
        }
      }
    }
  }
  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
}
