#REACT_dealerLocator {
  .panel {
    &.dealer {
      max-height: 67.6rem;
      overflow: scroll;

      .back-to-list {
        display: inline-block;
        margin-bottom: 1.5rem;
        margin-left: 0.5rem;
      }

      .rating-special {
        margin-bottom: 1rem;
        &-label {
          font-weight: 400;
          font-size: 0.8rem;
          text-transform: uppercase;
          top: 0;
          background-color: #121212;
          padding: 0.2rem 0.3rem;
          color: #fff;
          width: fit-content;
        }
      }
      .fa-stack {
        font-size: 8px;
      }

      li {
        margin: 0 0 0.5em;
        display: table;

        .icon-wrapper {
          display: table-cell;
          vertical-align: middle;
          width: 35px;
        }

        .text-wrapper {
          display: table-cell;
          vertical-align: middle;
          font-size: 11px;
          font-weight: 500;
        }
      }

      .dealer-name {
        font-weight: 700;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        line-height: 1;
      }

      .address {
        line-height: 1.1;
        margin-bottom: 1.5rem;
      }

      .categories-title,
      .services-title {
        font-weight: 400;
        font-size: 1.2rem;
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }
  }
}
