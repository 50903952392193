#REACT_dealerLocator {
  .partner-modal-wrapper {
    #partnerModalBackground {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: -500000000;
      background-color: #fff;
      opacity: 0;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;

      &.show {
        z-index: 500000000;
        opacity: 0.5;
      }
    }

    #partnerModal {
      position: fixed;
      right: -62rem;
      top: 0;
      height: 100vh;
      width: 60.6rem;
      padding: 5.3rem 6rem;
      color: #121212;
      z-index: 5000000000;
      background-color: #fff;
      border: 1px solid #f7f7f7;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;

      @media screen and (max-width: 991px) {
        left: -38rem;
        width: 32.5rem;
        padding: 2.4rem 2.1rem;
      }

      &.show {
        right: 0;

        @media screen and (max-width: 991px) {
          left: 0;
        }
      }

      .partner-modal-content {
        position: relative;

        h4:first-child {
          text-transform: uppercase;
          font-weight: 400;
          text-align: center;
          font-size: 17px;
          line-height: 24px;
          margin-bottom: 15px;
          margin-top: 5px;
        }

        div.img {
          height: 196px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          margin-bottom: 15px;

          @media screen and (max-width: 991px) {
            height: 130px;
          }
        }

        p {
          margin-bottom: 2rem;
        }
      }

      .partner-modal-close {
        position: absolute;
        top: 6.1rem;
        left: 6.1rem;
        background-color: #eee;
        padding: 0.3rem 0.5rem;
        color: #121212;
        font-size: 1.3rem;

        @media screen and (max-width: 991px) {
          display: none;
        }
      }

      .partner-modal-close-mobile {
        display: none;
      }

      @media screen and (max-width: 991px) {
        .partner-modal-close-mobile {
          display: block;
          position: absolute;
          top: 0;
          height: 7.2rem;
          width: 5rem;
          right: -5.1rem;
          font-size: 3rem;
          padding-left: 1.5rem;
          padding-top: 1.9rem;
          background-color: #fff;
          font-size: 25px;
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}
